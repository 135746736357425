import { type TypeToken, typeToken } from "zod-sql";

export type PlanTypeId = "plus" | "pro";
export type PlanFeature<A = any> = {
  id: string;
  name: string;
  description: string;
  argType?: TypeToken<A>;
  blurb?: (arg: A) => string;
  small?: boolean;
  changeText: (from: A, to: A) => string;
};
export const PLAN_FEATURES = {
  customThemes: {
    id: "customThemes",
    name: "Custom Themes",
    description: "Create and customize your own themes",
    changeText: (from, to) => (to ? "Use custom themes" : "No custom themes"),
  },

  multipleBoards: {
    id: "multipleBoards",
    name: "Multiple Boards",
    description: "Publish more than one board",
    argType: typeToken<number>(),
    blurb: (arg) => `${arg === Number.POSITIVE_INFINITY ? "Unlimited" : `Up to ${arg}`} Boards`,
    changeText: (from, to) =>
      to > from
        ? `Publish ${to - from} more board${to - from === 1 ? "" : "s"}`
        : `Only publish ${to} board${to === 1 ? "" : "s"}`,
  },

  imageUpload: {
    id: "imageUpload",
    name: "Image Upload",
    description: "Upload images to your boards",
    blurb: (arg) => (arg === Number.POSITIVE_INFINITY ? "Unlimited" : `${arg} Images per Board`),
    changeText: (from, to) =>
      to > from
        ? `Upload ${to - from} more image${to - from === 1 ? "" : "s"}`
        : `Only upload ${to} image${to === 1 ? "" : "s"}`,
  },

  removeAds: {
    id: "removeAds",
    name: "Remove Ads",
    description: "Enjoy your board without any ads",
    changeText: (from, to) => (to ? "Remove ads" : "Show ads"),
  },

  removeWatermark: {
    id: "removeWatermark",
    name: "No Watermark",
    description: "Remove the Greenlinks watermark",
    changeText: (from, to) => (to ? "Remove the watermark" : "Can't remove the watermark"),
  },
  analytics: {
    id: "analytics",
    name: "Analytics",
    description: "Track your board's performance",
    changeText: (from, to) => (to ? "View board analytics" : "Can't access board analytics"),
  },

  patterns: {
    id: "patterns",
    name: "Patterns",
    description: "Use patterns backgrounds in your boards",
    changeText: (from, to) => (to ? "Use patterns" : "No patterns"),
  },

  /*customDomain: {
    id: "customDomain",
    name: "Personal Domain",
    description: "Use your own domain name",
    changeText: (from, to) => (to ? "Use custom domains" : "No custom domains"),
  },*/
} as const satisfies Record<string, PlanFeature>;

export type PlanFeatureId = keyof typeof PLAN_FEATURES;
export type PlanFeatures = {
  [K in PlanFeatureId]: (typeof PLAN_FEATURES)[K] extends PlanFeature<infer A>
    ? A extends undefined
      ? boolean
      : A
    : never;
};

export interface PlanType {
  id: PlanTypeId;
  name: string;
  backgroundColors: string[];
  accentColor: string;
  foregroundColor: string;
  features: PlanFeatures;
  tier: number;
}

export const PLAN_TYPE_INFO = {
  plus: {
    id: "plus",
    name: "Plus",
    // neon blue purple
    backgroundColors: ["#36D1DC", "#42B8DF", "#5B86E5"],
    accentColor: "#42B8DF",
    foregroundColor: "var(--color-light)",
    features: {
      customThemes: true,
      multipleBoards: 2,
      imageUpload: 3,
      patterns: false,
      //customDomain: false,
      analytics: false,
      removeWatermark: false,
      removeAds: true,
    },
    tier: 10,
  },
  pro: {
    id: "pro",
    name: "Pro",
    backgroundColors: ["#FFD700", "#FFA500", "#FF6347"],
    accentColor: "#FFA500",
    foregroundColor: "var(--color-light)",
    features: {
      customThemes: true,
      multipleBoards: 5,
      imageUpload: 8,
      patterns: true,
      //customDomain: true,
      analytics: true,
      removeWatermark: true,
      removeAds: true,
    },
    tier: 20,
  },
} satisfies Record<PlanTypeId, PlanType>;
export const PLAN_TYPES = Object.keys(PLAN_TYPE_INFO) as PlanTypeId[];

export const getMaxPublishedBoards = (planTypeId: PlanTypeId | undefined) => {
  if (!planTypeId) {
    return 1;
  }
  return PLAN_TYPE_INFO[planTypeId].features.multipleBoards;
};

export const getNextPlan = (planTypeId: PlanTypeId | undefined) => {
  if (!planTypeId) {
    return PLAN_TYPE_INFO.plus;
  }
  return planTypeId === "plus" ? PLAN_TYPE_INFO.pro : undefined;
};
