"use client";

import { isRequestError } from "api-def";
import StatusPage from "src/components/app/StatusPage/StatusPage";
import * as Sentry from "@sentry/nextjs";
import React, { useEffect } from "react";
import type { AuthState } from "shared/SharedTypes";
import SignOutButton from "src/components/app/SignOutButton/SignOutButton";
import { AuthProvider } from "src/lib/state/AuthContext";
type ErrorType = "unknown" | "unauthorized" | "not-found" | "server-error" | "network-error";
export interface ErrorDisplayInfo {
  title: string;
  message: string;
}
const ERROR_DISPLAY_INFO: Record<ErrorType, ErrorDisplayInfo> = {
  unknown: {
    title: "Something went wrong",
    message: "Sorry, something went wrong. Please try again later."
  },
  unauthorized: {
    title: "Unauthorized",
    message: "You are not authorized to view this page."
  },
  "not-found": {
    title: "Not Found",
    message: "The page you are looking for does not exist."
  },
  "server-error": {
    title: "Server Error",
    message: "An error occurred on the server. Please try again later."
  },
  "network-error": {
    title: "Network Error",
    message: "There was a problem with your internet connection. Please try again later."
  }
};
export interface ErrorPageProps {
  error: Error;
  authState?: AuthState;
}
const ErrorPage = (props: ErrorPageProps) => {
  const {
    error,
    authState
  } = props;
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  let errorType: ErrorType = "unknown";
  if (isRequestError(error)) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      errorType = "unauthorized";
    } else if (error.response?.status === 404) {
      errorType = "not-found";
    } else if (error.response?.status === 500) {
      errorType = "server-error";
    }
  }
  const displayInfo = ERROR_DISPLAY_INFO[errorType];
  return <StatusPage title={displayInfo.title} data-sentry-element="StatusPage" data-sentry-component="ErrorPage" data-sentry-source-file="ErrorPage.tsx">
      <p>{displayInfo.message}</p>
      <br />
      {authState && <AuthProvider defaultAuthState={authState}>
          <SignOutButton />
        </AuthProvider>}
    </StatusPage>;
};
export default ErrorPage;